import { useRpQuery } from '~/composables/graphql'
import { useMutation } from '@tanstack/vue-query'
import type { CreateTicketInput } from '~/resources/graphql/graphql'
import { graphql } from '~/resources/graphql'

export function useCreateTicketMutation() {
    const query = useRpQuery()

    return useMutation({
        mutationFn: async (mutationData: CreateTicketInput) => {
            const response = await query(
                graphql(/** @lang GraphQL */ `
                    mutation CreateTicketMutation($input: CreateTicketInput!) {
                        createTicket(ticketInput: $input) {
                            __typename
                            ... on CreateTicketResult {
                                recordId
                                record {
                                    ...TicketConfirmationTicketFragment
                                }
                            }
                            ... on ValidationError {
                                fieldErrors {
                                    message
                                }
                            }
                        }
                    }
                `),
                {
                    input: mutationData
                }
            )

            if (response.createTicket.__typename === 'CreateTicketResult') {
                return response.createTicket
            } else {
                throw new Error()
            }
        }
    })
}
